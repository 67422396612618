$light-background: #eeeed2;
$dark-background: #769656;

$primary: #444;


$font-family: 'Arvo', serif;


$tablet: 769px;
$desktop: 960px;
$widescreen: 1200px;
$fullhd: 1430px;