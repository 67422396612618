@import './styles/index.scss';

.App {
  text-align: center;
  font-family: $font-family;
  width: 100%;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}