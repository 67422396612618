@import '../../styles/index.scss';

.chessboard {
    .container-boxes {
        max-width: 800px;
        max-height: 800px;
        width: 80%;
        height: 80%;
        display: grid;
        grid-template-rows: repeat(8, 1fr);
        grid-template-columns: repeat(8, 1fr);
    }
}